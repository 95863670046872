
/* The page occupies 100% of the screen */
html {
    height: 100%;
    width: 100%;
}

/* The content occupies the entire space available */
body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #455A64;
}

#root, .App {
    height: 100%;
    width: 100%;
}

#holder {
    display: grid;
    height: 100%;
    width: 100%;
}

model-viewer {
    width: 100%;
    height: 100%;
    outline: none;
}